import * as React from 'react';
import { AuthState } from '../../store/auth/types';
import * as AuthService from '../../utils/AuthService';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { createStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export interface HeaderViewProps {
  history: {
    push: (entry: any) => void;
    location: any;
  };
  theme: any;
  classes: any;
  auth: AuthState;
  loginError: (error: any) => void;
  loginSuccess: (profile: any) => void;
  loginRequest: () => void;
  logoutSuccess: () => void;
}
const drawerWidth = 240;
const styles = (theme: any) => createStyles({
  root: {
    flexGrow: 1,
  },
  titleText: {
    flexGrow: 1,
    fontFamily: 'MrEavesSmallCaps',
    fontVariant: 'titling-caps',
    fontSize: '2rem',
    marginLeft: '24px',
  },
  avatarImage: {
    borderRadius: '50%',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  appBar: {
    top: 0,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    top: '61px',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight: '48px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class HeaderView extends React.Component<HeaderViewProps> {
  public state = {
    anchorEl: null,
    drawerOpen: false,
  };

  public handleLoginClick = () => {
    AuthService.login();
    this.props.loginRequest();
  };

  public handleLogoutClick = () => {
    this.props.logoutSuccess();
    AuthService.logout();
    this.setState({ anchorEl: null });
    this.props.history.push({ pathname: '/' });
  };

  public handleMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  public handleClose = () => {
    this.setState({ anchorEl: null });
  };

  public handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };

  public handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  public render() {
    const { auth } = this.props;
    const { classes, theme } = this.props;
    const { anchorEl, drawerOpen } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <AppBar position="fixed" color={'inherit'}>
          <Toolbar variant="dense" disableGutters={true}>
            {/* <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon />
            </IconButton> */}
            <Typography variant="h6" color="inherit" className={classes.titleText} onClick={this.handleDrawerOpen}>
              Tome
            </Typography>
            {auth.isAuthenticated ? (
              <div>
                <IconButton
                  aria-owns={drawerOpen ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <img src={auth.profile.picture} height="35px" alt="profile" className={classes.avatarImage} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleClose}>Profile ({auth.profile.nickname})</MenuItem>
                  <MenuItem onClick={this.handleLogoutClick}>Logout</MenuItem>
                </Menu>
              </div>
            ) : (
              <IconButton
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleLoginClick}
                color="inherit"
              >
                <AccountCircle fontSize="large"/>
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader} style={{minHeight: '48px'}}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <Link to={'/'} style={{textDecoration: 'none'}} onClick={this.handleDrawerClose}>
              <ListItem button key={'Home'}>
                {/* <ListItemIcon><InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                <ListItemText primary={'Home'} />
              </ListItem>
            </Link>
            <Link to={'/about'} style={{textDecoration: 'none'}} onClick={this.handleDrawerClose}>
              <ListItem button key={'About'}>
                {/* <ListItemIcon><InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                <ListItemText primary={'About'} />
              </ListItem>
            </Link>
          </List>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HeaderView);
