import { MainPaneContext, Notification, NotificationType } from '../../types';
import { Command, CommandResponse } from './types';
import * as commandActions from './actions';
import { Dispatch } from 'redux';
import * as AuthService from '../../utils/AuthService';
import { v4 } from 'uuid';
import { notificationReceived } from '../notifications';

export declare type CommandHandlerAction = (command: CommandResponse, dispatch: Dispatch<any>) => CommandResponse;

export interface ClientCommandHandlerOptions {
  pattern: RegExp;
  excludeFromContexts?: MainPaneContext[];
  requiresAuth: boolean;
  handlerAction: CommandHandlerAction;
}

export class ClientCommandHandler {
  public pattern: RegExp;
  public excludeFromContexts: MainPaneContext[];
  public next: ClientCommandHandler;
  public handlerAction: CommandHandlerAction;
  public requiresAuth: boolean;

  constructor(options: ClientCommandHandlerOptions) {
    this.pattern = options.pattern;
    this.excludeFromContexts = options.excludeFromContexts || [];
    this.requiresAuth = options.requiresAuth;
    this.handlerAction = options.handlerAction;
  }

  public test(command: Command) {
    return this.pattern.test(command.commandText) &&
      this.excludeFromContexts.indexOf(command.mainPaneContext) === -1;
  }

  public setNext(handler: ClientCommandHandler) {
    this.next = handler;
    return this;
  }

  public async handleCommand(command: Command, dispatch: Dispatch<any>) {
    const response: CommandResponse = ClientCommandHandler.commandToResponse(command);
    try {
      if (this.test(command)) {
        dispatch(commandActions.commandRequest(command));
        if (AuthService.loggedIn() && this.requiresAuth ) {
          const msg = 'You are not authenticated, type "login" to get started!';
          const authError = ClientCommandHandler.createErrorNotification(command, msg);
          response.notifications.push(authError);
          dispatch(commandActions.commandError(command, msg));
        } else {
          await this.handlerAction(response, dispatch);
          dispatch(commandActions.commandSuccess(response));
        }
      } else if (this.next) {
        await this.next.handleCommand(response, dispatch);
      } else {
        const typo = ClientCommandHandler.createTypoNotification(command);
        response.notifications.push(typo);
      }
  
      dispatch(notificationReceived(response.notifications));
    } catch (e) {
      const cmdError = ClientCommandHandler.createErrorNotification(command, 
        'You are not authenticated, type "login" to get started!');
        dispatch(notificationReceived([cmdError]));
        dispatch(commandActions.commandError(command, e.message));
    } finally {
      return response;
    }
  }

  public static commandToResponse(command: Command, notifcation?: Notification | Notification[]) {
    const now = Date.now();
    const response: CommandResponse = {
      ...command, 
      processedTimestamp: now, 
      notifications: [],
      setMainPaneContext: command.mainPaneContext,
    };

    if ( Array.isArray(notifcation) ) {
      response.notifications.concat(notifcation);
    } else if ( notifcation && 'type' in notifcation) {
      response.notifications.push(notifcation as Notification);
    }
  
    return response;
  }

  public static createNotification(type: NotificationType, message: string, commandId: string) {
    const now = Date.now();
    const notification: Notification = {
      timestamp: now,
      message,
      type,
      id: v4(),
      commandId,
    };

    return notification;
  }
  public static createTypoNotification(cmd: Command, message?: string) {
    message = message || `Invalid command: "${cmd.commandText}"`;
    return ClientCommandHandler.createNotification('typo', message, cmd.commandId);
  }

  public static createErrorNotification(cmd: Command, error?: string) {
    error = error || `Error processing command: "${cmd.commandText}"`;
    return ClientCommandHandler.createNotification('error', error, cmd.commandId);
  }
}