import * as types from './types';
import { Notification } from '../../types';

const notificationsReducer = (
  state: types.NotificationState = {
    notificationsById: {
      // tslint:disable-next-line:max-line-length
      'playerSpeech': { id: 'playerSpeech', type: 'playerSpeech', message: 'Hello there!', commandId: '0', timestamp: 0 },
      'error': { id: 'error', type: 'error', message: 'You did something wrong', commandId: '0', timestamp: 0 }, 
      // tslint:disable-next-line:max-line-length
      'speech': { id: 'speech', type: 'speech', from: 'Karras', message: 'You stand before a magnificent library. You do not remember how you got here but you know that you have been traveling a long time.  Something about the architecture of the library is familiar to you.  What appears to be the main entrance to the library is ahead.  There is a kindly looking fellow standing off to the side.  He meets your eyes and nods a greeting.', commandId: '0', timestamp: 0 },
       // tslint:disable-next-line:max-line-length
      'info': { id: 'info', type: 'info', from: 'Karras', message: '[info] Kingston goes linkdead', commandId: '0', timestamp: 0 },
        // tslint:disable-next-line:max-line-length
      'telepathy': { id: 'telepathy', type: 'telepathy', from: 'Karras', message: `Karras telepathicly contacts you with 'Hey'`, timestamp: 0 },
    }
  },
  action: any
) => {
  switch (action.type) {
    case types.NOTIFICATION_RECEIVED:
      return addNotifications(state, action.payload.notification);
    default:
      return state;
  }
};

const addNotifications = (state: types.NotificationState, notifications: Notification[]) => {
  notifications.forEach((n) => {
    state.notificationsById[n.id] = n;
  });

  return {...state};
};

export default notificationsReducer;