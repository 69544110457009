
import { compose, createStore, Dispatch, Action, AnyAction, applyMiddleware } from 'redux';
import rootReducer from './reducer';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>;
}
const middlewares: any = [
  thunk
];

if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger());
}

const configureStore = (preloadedState?: any) =>
  createStore(
    rootReducer,
    preloadedState,
    compose(
      // // applyMiddleware(), // add your middlewares here
      // // Conditionally add the Redux DevTools extension enhancer if it is installed.
      // window.devToolsExtension ? window.devToolsExtension() : (f) => f
      applyMiddleware(...middlewares)
    )
  );
  
export default configureStore;