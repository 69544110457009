/* tslint:disable:max-line-length */
import { Realm } from '../../../types';
import placeholder from './loremIpsum';
import { region } from './region';
import { setting } from './setting';

export const realm: Realm = {
  id: 1,
  name: 'Celestial Realm',
  shortDescription: 'Placeholder short description' + placeholder,
  longDescription: placeholder,
  regions: [region],
  setting,
};