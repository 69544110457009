import * as types from './types';
import { Dispatch } from 'redux';
import { commandHanderChain } from './clientCommands';

export const commandRequest = (command: types.Command) => ({
  type: types.COMMAND_REQUEST,
  payload: { command }
});

export const commandSuccess = (commandResponse: types.CommandResponse) => ({
  type: types.COMMAND_SUCCESS,
  payload: { commandResponse }
});

export const commandError = (commandResponse: types.Command, error: any) => ({
  type: types.COMMAND_ERROR,
  payload: { commandResponse },
  error
});

export const submitCommand = (command: types.Command) => async (dispatch: Dispatch<any>) => {
  await commandHanderChain.handleCommand(command, dispatch);
};
