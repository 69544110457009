import { ClientCommandHandlerOptions, ClientCommandHandler, CommandHandlerAction } from '../ClientCommandHandler';
import { CommandResponse } from '../types';

const handlerAction: CommandHandlerAction = (command: CommandResponse) => {
  command.validCommand = true;
  const msg = command.commandText;
  const notification = ClientCommandHandler.createNotification('playerSpeech', msg, command.commandId);
  command.notifications.push(notification);
  return command;
};

const sayCommandOptions: ClientCommandHandlerOptions = {
  pattern: /^say|^\'%s'$/i,
  excludeFromContexts: [],
  requiresAuth: false,
  handlerAction
};

export const sayCommand = new ClientCommandHandler(sayCommandOptions);