import * as React from 'react';
import { Editor } from 'slate-react';
import Plain from 'slate-plain-serializer';
import { commandTypes } from '../../store/command';
import { CommandResponse } from 'src/store/command/types';
import { v4 } from 'uuid';
import { MainPaneContext } from 'src/types';

const slateStyle: React.CSSProperties = {
  fontSize: '2rem',
  fontFamily: 'ScalySans, Helvetica, Arial, sans-serif',
  background: '#fff',
  padding: '10px',
  marginTop: '0'
};

interface InputProps {
  history: CommandResponse[];
  mainPaneContext: MainPaneContext;
  isProcessing?: boolean;
  submitCommand: (command: commandTypes.Command) => void;
  logoutSuccess: () => void;
}

class Input extends React.Component<InputProps> {
  public state = {
    value: Plain.deserialize(''),
    historyIndex: null,
  };
  
  public editor: any;

  onChange = ({ value }: any) => {
    this.setState({ value });
  }

  onKeyDown = (event: any, change: any, next: any) => {
    switch (event.key) {
      case 'Enter':
        return this.onEnter();
      case 'ArrowUp':
        return this.onArrow(-1);
      case 'ArrowDown':
        return this.onArrow(1);
      case 'Escape':
        return this.onEscape();
      default:
        return next();
    }
  }

  onEscape = () => {
    const empty = Plain.deserialize('');
    this.setState({value: empty}, () => {
      this.editor.focus();
    });
  }

  onArrow = (offset: number) => {
    const historyIndex = this.state.historyIndex || this.props.history.length;
    const value = this.props.history[historyIndex + offset];
    if (! value ) { 
      return; 
    }
    const cmd = Plain.deserialize(value.commandText);
    this.setState({value: cmd, historyIndex: historyIndex + offset}, () => {
      this.editor.focus();
    });
  }

  onEnter = () => {
    const { mainPaneContext } = this.props; 
    const commandId = v4();
    const empty = Plain.deserialize('');
    this.props.submitCommand({
      commandId,
      mainPaneContext,
      commandText: Plain.serialize(this.state.value),
      submittedTimestamp: Date.now()
    });
    this.setState({value: empty}, () => {
      this.editor.focus();
    });
  }

  ref = (editor: Editor) => {
    this.editor = editor;
  }

  render() {
    return (
      <Editor 
        placeholder={'>'}
        value={this.state.value as any}
        onChange={this.onChange}
        onKeyDown={this.onKeyDown}
        style={slateStyle}
        autoFocus={true}
        ref={this.ref}
      />
    );
  }
}

export default Input;
