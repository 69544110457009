import * as React from 'react';
import * as slate from 'slate';
import { Notification } from '../types';
import * as utils from './utils';

export const deserializeNotifications = (notifications: Notification[]) => {

  const notificationsNodes = notifications.map((msg) => {
    switch (msg.type) {
      case 'command':
        return utils.createBlockNode('tomeNotification', [utils.boldBase00Leaf(msg.message)]);
      case 'error':
        return utils.createBlockNode('tomeNotification', [utils.boldRedLeaf(msg.message)]);
      case 'typo':
        return utils.createBlockNode('tomeNotification', [utils.yellowLeaf(msg.message)]);
      case 'info':
        return utils.createBlockNode('tomeNotification', [utils.yellowLeaf(msg.message)]);
      case 'telepathy':
        return utils.createBlockNode('tomeNotification', [utils.boldMagentaLeaf(msg.message)]);
      case 'whisper':
        return utils.createBlockNode('tomeNotification', [utils.base01Leaf(msg.message)]);
      case 'emote':
        return utils.createBlockNode('tomeNotification', [utils.boldBase01Leaf(msg.message)]);
      case 'speech':
        return utils.createBlockNode('tomeNotification', createSpeachBlock(msg));
      case 'playerSpeech':
        return utils.createBlockNode('tomeNotification', createPlayerSpeachBlock(msg));
      default:
        return utils.createBlockNode('tomeNotification', [utils.base00Leaf(msg.message)]);
    }
  });

  const document = utils.createDocument([
    utils.createBlockNode('tomeNoticationList', notificationsNodes),
  ]);
  return slate.Value.fromJS(document);
};

const createPlayerSpeachBlock = (msg: Notification) => {
  return [
    utils.createStyledLeaf(`You say '`, 'yellow', false),
    utils.createStyledLeaf(msg.message, 'base00', false),
    utils.createStyledLeaf(`'`, 'yellow', false),
  ];
};

const createSpeachBlock = (msg: Notification) => {
  return [
    utils.createStyledLeaf(`${msg.from} says '`, 'yellow', false),
    utils.createStyledLeaf(msg.message, 'base00', false),
    utils.createStyledLeaf(`'`, 'yellow', false),
  ];
};

export const renderMark = (props: any) => {
  switch (props.mark.type) {
    case 'color': 
      return <utils.ColorMark {...props} />;
    case 'bold':
      return <utils.BoldMark {...props} />;
    default:
      return null;
  }
};

export const renderNode = (props: any, editor: any, next: any) => {
  switch (props.node.type) {
    case 'tomeNoticationList':
      return <TomeNotificationList {...props} />;
    case 'tomeNotification':
      return <TomeNotification {...props} />;
    default:
      return next();
  }
};

export const TomeNotificationList = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'ScalySans',
    fontSize: '1.0rem',
    listStyleType: 'none',
    paddingLeft: 0,
    paddingTop: 0,
    marginTop: 0,
    lineHeight: 1.8,
  };

  return (
    <ul {...props.attributes} style={style}>
      {props.children}
    </ul>
  );
};

export const TomeNotification = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'ScalySans',
    fontSize: '1.0rem',
    lineHeight: '1.3',
    paddingBottom: '10px'
  };

  return (
    <li {...props.attributes} style={style}>
      {props.children}
    </li>
  );
};

export const notificationPlugin = {
  renderNode,
  renderMark,
};