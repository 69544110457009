import * as types from './types';
import { MainPaneContext } from '../../types';

export interface CommandState {
  isFetching: boolean;
  mainPaneContext: MainPaneContext;
  commandHistory: types.CommandResponse[];
  error?: any;
}

const commandReducer = (
  state = {
    isProcessing: false,
    commandHistory: [],
    error: null,
    mainPaneContext: 'location'
  },
  action: any
) => {
  switch (action.type) {
    case types.COMMAND_REQUEST:
      return {
        ...state,
        isProcessing: true,
        error: null
      };
    case types.COMMAND_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        commandHistory: state.commandHistory.concat(action.payload.commandResponse),
        mainPaneContext: action.payload.commandResponse.setMainPainContext
      };
    case types.COMMAND_ERROR:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        commandHistory: state.commandHistory.concat(action.payload.commandResponse),
      };
    default:
      return state;
  }
};

export default commandReducer;