import { combineReducers } from 'redux';

import authReducer from './auth';
import commandReducer from './command';
import notificationsReducer from './notifications/reducer';
import locationReducer from './location/reducer';
import playerReducer from './player/reducer';

export default combineReducers({
  auth: authReducer,
  command: commandReducer,
  notifications: notificationsReducer,
  location: locationReducer,
  player: playerReducer,
});