import { ClientCommandHandlerOptions, ClientCommandHandler, CommandHandlerAction } from '../ClientCommandHandler';
import { CommandResponse } from '../types';
import * as AuthService from '../../../utils/AuthService';

const handlerAction: CommandHandlerAction = (command: CommandResponse) => {
  command.validCommand = true;
  AuthService.login();
  const notification = ClientCommandHandler.createNotification('info', 'Attempting to login...', command.commandId);
  command.notifications.push(notification);
  return command;
};

const loginCommandOptions: ClientCommandHandlerOptions = {
  pattern: /^login$|^logon$/i,
  excludeFromContexts: [],
  requiresAuth: false,
  handlerAction
};

export const loginCommand = new ClientCommandHandler(loginCommandOptions);