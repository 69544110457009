import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { withRouter } from 'react-router-dom';
import { authActions } from '../../store/auth';
import AppView from './AppView';
import withRoot from '../../withRoot';

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  loginSuccess: (profile: any) => dispatch(authActions.loginSuccess(profile)),
  loginError: (error: any) => dispatch(authActions.loginError(error))
});

export default withRouter<any>(connect(null, mapDispatchToProps)(withRoot((AppView))));
