
export const LOGIN_REQUEST = 'Auth0/auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'Auth0/auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'Auth0/auth/LOGIN_ERROR';
export const LOGOUT_SUCCESS = 'Auth0/auth/LOGOUT_SUCCESS';

export interface Auth0Profile {
  username: string;
  picture: string;
  nickname: string;
}

export interface AuthState {
  isAuthenticated: boolean;
  isFetching: boolean;
  profile: Auth0Profile;
  error?: any;
}