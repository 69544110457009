import { ClientCommandHandlerOptions, ClientCommandHandler, CommandHandlerAction } from '../ClientCommandHandler';
import { CommandResponse } from '../types';
import * as AuthService from '../../../utils/AuthService';
import * as authActions from '../../auth/actions';
import { Dispatch } from 'redux';

const handlerAction: CommandHandlerAction = (command: CommandResponse, dispatch: Dispatch<any>) => {
  command.validCommand = true;
  AuthService.logout();
  dispatch(authActions.logoutSuccess());
  const notification = ClientCommandHandler.createNotification('info', 'Logging out...', command.commandId);
  command.notifications.push(notification);
  return command;
};

const logoutCommandOptions: ClientCommandHandlerOptions = {
  pattern: /^logout$|^quit$/i,
  excludeFromContexts: [],
  requiresAuth: true,
  handlerAction
};

export const logoutCommand = new ClientCommandHandler(logoutCommandOptions);