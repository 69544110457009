/* tslint:disable:max-line-length */
import { Location } from '../../../types';
import placeholder from './loremIpsum';
import { area } from './area';

export const location: Location = {
  id: 1,
  name: 'Before the grand library',
  shortDescription: `You stand before a magnificent library. You do not remember how you got here but you know that you have been traveling a long time.  Something about the architecture of the library is familiar to you.  What appears to be the main entrance to the library is ahead.  There is a kindly looking fellow standing off to the side.  He meets your eyes and nods a greeting.`,
  longDescription: placeholder,
  environmentalConditions: [],
  obviousExits: ['enter'],
  nonPlayerCharacters: ['Karras, binder of Glyphs'],
  playerCharacters: [],
  objects: ['A large book'],
  area,
};

export const locations = [location];