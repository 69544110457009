import * as React from 'react';
import { Editor } from 'slate-react';
import Plain from 'slate-plain-serializer';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Player } from '../../types';
import { playerPlugin, deserializePlayerSummary } from '../../slate/player-plugin';

interface PlayerPaneViewProps {
  player: Player;
  theme: any;
  classes: any;
}

const styles = (theme: any) => createStyles({
  paneWrapper: {
    // borderBottom: '1px #93a1a1 solid',
    // marginBottom: '10px',
  },
  slateStyle: {
    fontSize: '1rem',
    fontFamily: 'Texgyrebonum, Helvetica, Arial, sans-serif',
    textAlign: 'justify',
    textJustify: 'inter-word',
    paddingRight: '10px',
  }
});

class PlayerPaneView extends React.Component<PlayerPaneViewProps> {
  public state = {
    player: Plain.deserialize('')
  };
  public plugins: any[];
  public editor: any;
  public bottom: any;

  constructor(props: PlayerPaneViewProps) {
    super(props);
    this.plugins = [playerPlugin as any];
  }
  onChange = ({ value }: any) => {
    this.setState({ value });
  }

  componentDidUpdate(prevProps: PlayerPaneViewProps) {
    if (this.props.player !== prevProps.player) {
      const value = deserializePlayerSummary(this.props.player);
      this.setState({ player: value });
    }
  }

  componentDidMount() {
    const value = deserializePlayerSummary(this.props.player);
    this.setState({ player: value });
  }
  
  ref = (editor: Editor) => {
    this.editor = editor;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.paneWrapper}>
        <Editor
          value={this.state.player}
          readOnly={true}
          className={classes.slateStyle}
          ref={this.ref}
          plugins={this.plugins}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PlayerPaneView);
