import * as React from 'react';
import { TomeColor } from '../types';
import { colorMap } from '../utils/solorize-colors';
import * as slate from 'slate';

export const createStyledLeaf = (
  text: string, 
  color: TomeColor = 'base01', 
  bold: boolean = false, 
  italic: boolean = false) => {
    const boldMark = { type: 'bold', data: {} };
    const italicMark = { type: 'italic', data: {} };
    const marks = [
      createColorMark(color),
    ] as any;
    if (bold) {
      marks.push(boldMark);
    }
    if (italic) {
      marks.push(italicMark);
    }
    return createLeafNode(text, marks);
};

export const yellowLeaf = (text: string) => createLeafNode(text, [createColorMark('yellow')]);
export const orangeLeaf = (text: string) => createLeafNode(text, [createColorMark('orange')]);
export const redLeaf = (text: string) => createLeafNode(text, [createColorMark('red')]);
export const magentaLeaf = (text: string) => createLeafNode(text, [createColorMark('magenta')]);
export const violetLeaf = (text: string) => createLeafNode(text, [createColorMark('violet')]);
export const blueLeaf = (text: string) => createLeafNode(text, [createColorMark('blue')]);
export const cyanLeaf = (text: string) => createLeafNode(text, [createColorMark('cyan')]);
export const greenLeaf = (text: string) => createLeafNode(text, [createColorMark('green')]);
export const base01Leaf = (text: string) => createLeafNode(text, [createColorMark('base01')]);
export const base00Leaf = (text: string) => createLeafNode(text, [createColorMark('base00')]);
export const base1Leaf = (text: string) => createLeafNode(text, [createColorMark('base1')]);
export const base0Leaf = (text: string) => createLeafNode(text, [createColorMark('base0')]);

export const boldYellowLeaf = (text: string) => createStyledLeaf(text, 'yellow', true);
export const boldOrangeLeaf = (text: string) => createStyledLeaf(text, 'orange', true);
export const boldRedLeaf = (text: string) => createStyledLeaf(text, 'red', true);
export const boldMagentaLeaf = (text: string) => createStyledLeaf(text, 'magenta', true);
export const boldVioletLeaf = (text: string) => createStyledLeaf(text, 'violet', true);
export const boldBlueLeaf = (text: string) => createStyledLeaf(text, 'blue', true);
export const boldCyanLeaf = (text: string) => createStyledLeaf(text, 'cyan', true);
export const boldGreenLeaf = (text: string) => createStyledLeaf(text, 'green', true);
export const boldBase01Leaf = (text: string) => createStyledLeaf(text, 'base01', true);
export const boldBase00Leaf = (text: string) => createStyledLeaf(text, 'base00', true);
export const boldBase1Leaf = (text: string) => createStyledLeaf(text, 'base1', true);
export const boldBase0Leaf = (text: string) => createStyledLeaf(text, 'base0', true);

export const createColorMark = (color: TomeColor) => {
  const data = {
    hex: colorMap[color],
    key: color,
  };
  return {
    type: 'color',
    data
  };
};

export const createLeafNode = (text: string, marks?: any[]) => {
  return {
    object: 'text',
    leaves: [
      {
        text,
        marks,
      },
    ],
  };
};

export const createBlockNode = (type: string, children: any[], marks?: any[]) => {
  return { object: 'block', type, nodes: [...children] };
};

export const createDocument = (children: any[]) => {
  return { 
    document: { 
      nodes: [...children]
    }
  };
};

export interface ColorMarkOptions {
  mark: any;
  children: slate.Node[];
}

export const ColorMark = (options: ColorMarkOptions) => {
  const hex = options.mark.data.get('hex');
  
  return (
    <span style={{ color: hex }}>
      {options.children}
    </span>
  );
};

export const BoldMark = (props: any) => {
  return <strong>{props.children}</strong>;
};
