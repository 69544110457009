export const colorMap = {
  yellow: '#b58900',
  orange: '#cb4b16',
  red: '#dc322f',
  magenta: '#d33682',
  violet: '#6c71c4',
  blue: '#268bd2',
  cyan: '#2aa198',
  green: '#859900',
  base01: '#586e75',
  base00: '#657b83',
  base1: '#93a1a1',
  base0: '#839496'
};