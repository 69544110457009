/* tslint:disable:max-line-length */
import { Setting } from '../../../types';
import placeholder from './loremIpsum';
import { realm } from './realm';

export const setting: Setting = {
  id: 1,
  name: 'TODO: Tome Setting',
  shortDescription: 'TODO Tome setting short desc',
  longDescription: placeholder,
  realms: [realm],
};