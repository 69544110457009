import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../../components/HomePage';
import AboutPage from '../../components/AboutPage';
import NotFoundPage from '../../components/NotFoundPage';
import * as AuthService from '../../utils/AuthService';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { createStyles, withStyles } from '@material-ui/core';

export interface AppViewProps {
  history: {
    push: (entry: any) => void;
    location: any;
  };
  loginError: (error: any) => void;
  loginSuccess: (profile: any) => void;
  theme: any;
  classes: any;
}

const styles = (theme: any) => createStyles({
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  wrapper: {
    height: 'calc(100vh - 90px)',
    paddingTop: '60px',
    backgroundImage: 'url(images/background.jpg)',
    backgroundSize: 'cover',
  }
});

class AppView extends React.Component<AppViewProps> {

  componentWillMount() {
    const { history, loginError, loginSuccess } = this.props;
    // Add callback for lock's `authenticated` event
    AuthService.lock.on('authenticated', authResult => {
      AuthService.lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          return loginError(error);
        }
        AuthService.setToken(authResult.idToken); // static method
        AuthService.setProfile(profile); // static method
        loginSuccess(profile);
        history.push({ pathname: '/' });
        AuthService.lock.hide();
      });
    });
    // Add callback for lock's `authorization_error` event
    AuthService.lock.on('authorization_error', error => {
      loginError(error);
      history.push({ pathname: '/' });
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.wrapper}>
        <Header />
        <main className={classes.content}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/about" component={AboutPage} />
            <Route path={process.env.PUBLIC_URL} component={NotFoundPage} />
          </Switch>
        </main>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AppView);