import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MainPaneView from './MainPaneView';
import withRoot from '../../withRoot';
import { Store } from '../../types';

const mapStateToProps = (state: Store) => ({
  content: state.location,
  activeContext: state.command.mainPaneContext,
});

export default withRouter<any>(connect(mapStateToProps)(withRoot((MainPaneView))));
