/* tslint:disable:max-line-length */
import { Area } from '../../../types';
import placeholder from './loremIpsum';
import { locations } from './location';
import { region } from './region';

export const area: Area = {
  id: 1,
  environmentalConditions: [],
  title: 'Grand Celestial Library',
  shortDescription: 'No one knows when it was built or who created it.  The oldest Gyphys awakenting before it long ago.  What is known is that the Celestial Library has stood since time immemorial, a symbol of knowledge and wisdom to all who walk the realms.',
  longDescription: placeholder,
  locations,
  region,
  mapUrl: '/map/library.json'
};
