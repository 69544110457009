import * as React from 'react';
import { Editor } from 'slate-react';
import Plain from 'slate-plain-serializer';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Notification } from '../../types';
import ScrollableFeed from 'react-scrollable-feed';
import { notificationPlugin, deserializeNotifications } from '../../slate/notifications-plugin';

interface NotificationPaneViewProps {
  notifications: Notification[];
  theme: any;
  classes: any;
}

const styles = (theme: any) => createStyles({
  notificationPaneWrapper: {
    height: 'calc(100vh - 230px)'
  },
  slateStyle: {
    fontSize: '1rem',
    fontFamily: 'Texgyrebonum, Helvetica, Arial, sans-serif',
    textAlign: 'justify',
    textJustify: 'inter-word',
    paddingRight: '10px',
  }
});

class NotificationPaneView extends React.Component<NotificationPaneViewProps> {
  public state = {
    notifications: Plain.deserialize('')
  };
  public plugins: any[];
  public editor: any;
  public bottom: any;

  constructor(props: NotificationPaneViewProps) {
    super(props);
    this.plugins = [notificationPlugin as any];
  }
  onChange = ({ value }: any) => {
    this.setState({ value });
  }

  componentDidUpdate(prevProps: NotificationPaneViewProps) {
    if (this.props.notifications !== prevProps.notifications) {
      // const notifications = this.props.notifications.map(n => {
      //   return n.message;
      // });
      // const value = Plain.deserialize(notifications.join('\n'));
      // value.document.nodes.filter(node => isHeader(node.type));
      const value = deserializeNotifications(this.props.notifications);
      this.setState({notifications: value });
    }
  }

  componentDidMount() {
    const value = deserializeNotifications(this.props.notifications);
    this.setState({notifications: value });
  }
  
  ref = (editor: Editor) => {
    this.editor = editor;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.notificationPaneWrapper} id={'scroll-pane'}>
        <ScrollableFeed>
          <Editor
            value={this.state.notifications}
            readOnly={true}
            className={classes.slateStyle}
            ref={this.ref}
            plugins={this.plugins}
          />
        </ScrollableFeed>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NotificationPaneView);
