import * as React from 'react';
import * as slate from 'slate';
import { Player, CreatureResource } from '../types';
import * as utils from './utils';

const resourceBlock = (resource: CreatureResource) => {
  const block = utils.createBlockNode('tomePlayerResourceItem', [
    utils.boldYellowLeaf(`${resource.key} (`),
    utils.blueLeaf(resource.current.toString()),
    utils.blueLeaf('/'),
    utils.blueLeaf(resource.max.toString()),
    utils.boldYellowLeaf(`) `),
  ]);
  return block;
};

const resourceList = (player: Player) => {
  return [
    resourceBlock(player.hp),
    resourceBlock(player.sp),
    resourceBlock(player.ep),
  ];
};

const levelBlock = (player: Player) => {
  return [
    utils.base00Leaf(' level '),
    utils.base00Leaf(player.level.toString()),
    utils.base00Leaf(`, `),
    utils.base00Leaf(`${player.race.size} ${player.race.name}`),
  ];
};

export const deserializePlayerSummary = (player: Player) => {
  const { name } = player;
  
  const document = utils.createDocument([
    utils.createBlockNode('tomePlayerName', [utils.boldRedLeaf(name)]),
    utils.createBlockNode('tomePlayerLevel', levelBlock(player)),
    utils.createBlockNode('tomePlayerResourceList', resourceList(player)),
  ]);
  return slate.Value.fromJS(document);
};

export const renderMark = (props: any) => {
  switch (props.mark.type) {
    case 'color': 
      return <utils.ColorMark {...props} />;
    default:
      return null;
  }
};

export const renderNode = (props: any, editor: any, next: any) => {
  switch (props.node.type) {
    case 'tomePlayerName':
      return <TomePlayerName {...props} />;
    case 'tomePlayerLevel':
      return <TomePlayerLevel {...props} />;
    case 'tomePlayerResourceList':
      return <TomePlayerResourceList {...props} />;
    case 'tomePlayerResourceItem':
      return <TomePlayerResourceItem {...props} />;
    default:
      return next();
  }
};

export const TomePlayerName = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'MrEavesSmallCaps',
    fontSize: '1.8rem',
    float: 'left',
    display: 'inline-block',
  };

  return (
    <span {...props.attributes} style={style}>
      {props.children}
    </span>
  );
};

export const TomePlayerLevel = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'ScalySans',
    fontStyle: 'italic',
    fontSize: '1.0rem',
    float: 'left',
    display: 'inline-block',
    marginTop: '11px',
    marginLeft: '5px',
  };

  return (
    <span {...props.attributes} style={style}>
      {props.children}
    </span>
  );
};

export const TomePlayerResourceList = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'ScalySans',
    fontSize: '1.8rem',
    listStyleType: 'none',
    paddingLeft: 0,
    paddingTop: 0,
    marginTop: '8px',
    lineHeight: 1.4,
    float: 'left',
    display: 'inline-block',
    marginLeft: '10px'
  };

  return (
    <ul {...props.attributes} style={style}>
      {props.children}
    </ul>
  );
};

export const TomePlayerResourceItem = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'ScalySans',
    fontSize: '1.0rem',
    float: 'left',
  };

  return (
    <li {...props.attributes} style={style}>
      {props.children}
    </li>
  );
};

export const playerPlugin = {
  renderNode,
  renderMark,
};