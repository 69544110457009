import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NotificationPaneView from './NotificationPaneView';
import withRoot from '../../withRoot';
import { Store } from '../../types';
import { getNotifications } from '../../selectors';

const mapStateToProps = (state: Store) => ({
  notifications: getNotifications(state),
});

export default withRouter<any>(connect(mapStateToProps)(withRoot((NotificationPaneView))));
