import * as React from 'react';
import { Editor } from 'slate-react';
import Plain from 'slate-plain-serializer';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Location, MainPaneContext } from '../../types';
import { environmentPlugin, deserializeShortLocation } from '../../slate/environment-plugin';

interface MainPaneViewProps {
  activeContext: MainPaneContext; 
  content: Location;
  theme: any;
  classes: any;
}

const styles = (theme: any) => createStyles({
  PaneWrapper: {
    height: 'calc(100vh - 190px)'
  },
  slateStyle: {
    fontSize: '1rem',
    fontFamily: 'Bookinsanity, Helvetica, Arial, sans-serif',
    textAlign: 'justify',
    textJustify: 'inter-word'
  }
});

class MainPaneView extends React.Component<MainPaneViewProps> {

  public static defaultProps: Partial<MainPaneViewProps> = {
    activeContext: 'location',
  };

  public state = {
    paneValue: Plain.deserialize('')
  };

  public plugins: any[];
  public editor: any;
  public bottom: any;

  constructor(props: MainPaneViewProps) {
    super(props);
    this.plugins = [environmentPlugin] as any;
  }

  onChange = ({ value }: any) => {
    this.setState({ value });
  }
  componentDidMount() {
    const value = deserializeShortLocation(this.props.content);
    this.setState({ paneValue: value });
  }

  componentDidUpdate(prevProps: MainPaneViewProps) {
    if (this.props.content !== prevProps.content) {
      const value = deserializeShortLocation(this.props.content);
      this.setState({ paneValue: value });
    }
  }
  
  ref = (editor: Editor) => {
    this.editor = editor;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.PaneWrapper}>
        <Editor
          value={this.state.paneValue}
          readOnly={true}
          className={classes.slateStyle}
          ref={this.ref}
          plugins={this.plugins}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MainPaneView);
