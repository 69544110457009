import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import MainPane from '../containers/mainPane/MainPane';
import NotificationPane from '../containers/notificationPane/NotificationPane';

class Home extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={40}>
          <Grid item xs style={{borderRight: '1px #93a1a1 solid'}}>
            <MainPane {...this.props} />
          </Grid>
          <Grid item xs style={{paddingRight: 0, marginRight: 0}}>
            <NotificationPane {...this.props} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Home;
