import * as React from 'react';
import * as slate from 'slate';
import { Location } from '../types';
import * as utils from './utils';
import { MapProvider, Map } from 'react-tiled';

const obviousExitsBlock = (location: Location) => {
  const { obviousExits } = location;
  const obviousExitsNodes = [];

  if (!obviousExits || obviousExits.length === 0) {
    obviousExitsNodes.push(utils.yellowLeaf('There are obvious exits.'));
    return obviousExits;
  }

  const exitNodes = obviousExits.map((exit: string, i: number) => {
    if (obviousExits.length === 1) {
      return [
        utils.yellowLeaf('('),
        utils.redLeaf(exit),
        utils.yellowLeaf(')')
      ];
    } else if (i === 0) {
      return [
        utils.yellowLeaf('('),
        utils.redLeaf(exit),
        utils.yellowLeaf(' ,')
      ];
    } else if (i < obviousExits.length - 1) {
      return [
        utils.redLeaf(exit),
        utils.yellowLeaf(' ,')
      ];
    } else { 
      return [
        utils.yellowLeaf(', and '), 
        utils.createLeafNode(exit,
          [utils.createColorMark('red')]),
          utils.yellowLeaf(')')
        ];
    }
  });

  const label = `Obvious exit${obviousExits.length > 1 ? 's are' : ''}: `;
  obviousExitsNodes.push(utils.yellowLeaf(label));
  obviousExitsNodes.push(...exitNodes[0]);
  
  return obviousExitsNodes;
};

const npcBlock = (location: Location) => {
  return location.nonPlayerCharacters.map((npc: string) => {
    return utils.createBlockNode('TomeNPCListItem', [utils.magentaLeaf(npc)]);
  });
};

export const deserializeShortLocation = (location: Location) => {
  const { name, shortDescription, objects } = location;

  const titleNode = utils.cyanLeaf(name);
  const shortNode = utils.base00Leaf(shortDescription);
  const obviousExitsNodes = obviousExitsBlock(location);
  const objectNodes = utils.greenLeaf(objects.join(','));
  const npcNodes = npcBlock(location);
  // const mapNode = utils.createLeafNode(area.mapUrl);

  const document = utils.createDocument([
    utils.createBlockNode('tomeTitle', [titleNode]),
    utils.createBlockNode('tomeShortDesc', [shortNode]),
    utils.createBlockNode('tomeObviousExits', obviousExitsNodes),
    utils.createBlockNode('tomeObjects', [objectNodes]),
    utils.createBlockNode('TomeNPCList', npcNodes),
    // utils.createBlockNode('TomeAreaMap', [mapNode]),
  ]);
  return slate.Value.fromJS(document);
};

interface ColorMarkOptions {
  mark: any;
  children: slate.Node[];
}

export const ColorMark = (options: ColorMarkOptions) => {
  const hex = options.mark.data.get('hex');
  
  return (
    <span style={{ color: hex }}>
      {options.children}
    </span>
  );
};

export const renderMark = (props: any) => {
  switch (props.mark.type) {
    case 'color': 
      return <ColorMark {...props} />;
    default:
      return null;
  }
};

export const renderNode = (props: any, editor: any, next: any) => {
  switch (props.node.type) {
    case 'tomeTitle':
      return <TomeTile {...props} />;
    case 'tomeObviousExits':
      return <TomeObviousExits {...props} />;
    case 'tomeObjects':
      return <TomeObjects {...props} />;
    case 'TomeNPCList':
      return <TomeNPCList {...props} />;
    case 'TomeNPCListItem':
      return <TomeNPCListItem {...props} />;
    case 'TomeAreaMap':
      return <TomeAreaMap {...props} />;
    default:
      return next();
  }
};

export const TomeTile = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'MrEavesSmallCaps',
    fontSize: '1.8rem',
    lineHeight: 1.4,
  };

  return (
    <span {...props.attributes} style={style}>
      {props.children}
    </span>
  );
};

export const TomeObviousExits = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'ScalySans',
    fontSize: '1.0rem',
    fontWeight: 'bold',
    lineHeight: 2,
  };

  return (
    <div {...props.attributes} style={style}>
      {props.children}
    </div>
  );
};

export const TomeObjects = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'ScalySans',
    fontSize: '1.0rem',
    fontWeight: 'bold',
  };

  return (
    <div {...props.attributes} style={style}>
      {props.children}
    </div>
  );
};

export const TomeNPCList = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'ScalySans',
    fontSize: '1.0rem',
    fontWeight: 'bold',
    listStyleType: 'none',
    paddingLeft: 0,
    paddingTop: 0,
    marginTop: 0,
    lineHeight: 2,
  };

  return (
    <ul {...props.attributes} style={style}>
      {props.children}
    </ul>
  );
};

export const TomeNPCListItem = (props: any) => {
  const style: React.CSSProperties = {
    fontFamily: 'ScalySans',
    fontSize: '1.0rem',
    fontWeight: 'bold',
  };

  return (
    <li {...props.attributes} style={style}>
      {props.children}
    </li>
  );
};

export const TomeAreaMap = (props: any) => {
  const style: React.CSSProperties = {
    display: 'flex',
    // justifyContent: 'flex-end',
    // alignItems: 'center',
    flexGrow: 1,
    marginRight: '30px'
    // backgroundColor: '#1c1117',
  };

  return (
    <MapProvider mapUrl={process.env.PUBLIC_URL + '/maps/area.json'}>
      <div style={style}>
        <Map style={{ backgroundColor: '#eee8d5', height: '100%' }} />
      </div>
    </MapProvider>
  );
};

export const environmentPlugin = {
  renderNode,
  renderMark,
};