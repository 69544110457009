import * as React from 'react';

class NotFoundPage extends React.Component<any> {
  render() {
    return (
      <div>
        <h2>404</h2>
      </div>
    );
  }
}

export default NotFoundPage;
