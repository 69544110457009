import { Notification, MainPaneContext } from '../../types';
export const COMMAND_REQUEST = 'command/COMMAND_REQUEST';
export const COMMAND_SUCCESS = 'command/COMMAND_SUCCESS';
export const COMMAND_ERROR = 'command/COMMAND_ERROR';

export interface Command {
  commandId: string;
  commandText: string;
  mainPaneContext: MainPaneContext;
  submittedTimestamp: number;
  processedTimestamp?: number;
  validCommand?: boolean;
}

export interface CommandResponse extends Command {
  notifications: Notification[];
  setMainPaneContext?: MainPaneContext;
}

export interface CommandState {
  isProcessing: boolean;
  commandHistory: CommandResponse[];
  mainPaneContext: MainPaneContext;
  error?: any;
}