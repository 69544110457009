import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PlayerPaneView from './PlayerPaneView';
import withRoot from '../../withRoot';
import { Store } from '../../types';

const mapStateToProps = (state: Store) => ({
  player: state.player,
});

export default withRouter<any>(connect(mapStateToProps)(withRoot((PlayerPaneView))));
