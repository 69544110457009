import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import { createStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Input from './Input';
import classNames from 'classnames';
import { Command, CommandResponse } from 'src/store/command/types';
import PlayerPane from '../playerPane/PlayerPane';
import Grid from '@material-ui/core/Grid';
import { MainPaneContext } from 'src/types';

export interface FooterViewProps {
  history: CommandResponse[];
  mainPaneContext: MainPaneContext;
  submitCommand: (command: Command) => void;
  logoutSuccess: () => void;
  theme: any;
  classes: any;
}

const styles = (theme: any) => createStyles({
  footer: {
    background: '#eee8d5',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '5px'
  },
});

class FooterView extends React.Component<FooterViewProps> {
  public state = {
  };

  public render() {
    // const { auth } = this.props;
    const { classes } = this.props;
  
    return (
      <Paper className={classNames(classes.MuiAppBar, classes.footer)}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{height: '30px', padding: '0 15px 0 15px'}}>
            <PlayerPane {...this.props} />
          </Grid>
          <Grid item xs={12} style={{padding: '0 15px 15px 15px'}}>
            <Input {...this.props}/>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(FooterView);
