import * as React from 'react';

class AboutPage extends React.Component<any> {
  render() {
    return (
      <div>
       About
      </div>
    );
  }
}

export default AboutPage;
