import * as types from './types';
import { defaultPlayer } from './defaultPlayer';

const playerReducer = (
  state: types.PlayerState = {
    ...defaultPlayer,
    lastUpdatedTimestamp: 0
  },
  action: any
) => {
  switch (action.type) {
    case types.PLAYER_UPDATED:
      return {
        ...state,
        ...action.payload,
        lastUpdatedTimestamp: Date.now()
      };
    default:
      return state;
  }
};

export default playerReducer;