import * as types from './types';

export const loginRequest = () => ({
  type: types.LOGIN_REQUEST
});

export const loginSuccess = (profile: any) => ({
  type: types.LOGIN_SUCCESS,
  payload: { profile }
});

export const loginError = (error: any) => ({
  type: types.LOGIN_ERROR,
  error
});

export const logoutSuccess = () => ({
  type: types.LOGOUT_SUCCESS
});