import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch, AnyAction } from 'redux';
import { authActions } from '../../store/auth';
import { Store } from '../../types';

import HeaderView from './HeaderView';

const mapStateToProps = (state: Store) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  loginRequest: () => dispatch(authActions.loginRequest()),
  logoutSuccess: () => dispatch(authActions.logoutSuccess())
});

export default withRouter<any>(
  connect(mapStateToProps, mapDispatchToProps)(HeaderView)
);