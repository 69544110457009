import * as types from './types';
import { location as defaultLocation } from './introLocation/location';

const locationReducer = (
  state: types.LocationState = {
    ...defaultLocation,
    lastUpdatedTimestamp: 0
  },
  action: any
) => {
  switch (action.type) {
    case types.LOCATION_UPDATED:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default locationReducer;