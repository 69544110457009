import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dispatch, AnyAction } from 'redux';
import { commandActions, commandTypes } from '../../store/command';
import { Store } from '../../types';
import { authActions } from '../../store/auth';

import FooterView from './FooterView';

const mapStateToProps = (state: Store) => ({
  history: state.command.commandHistory,
  mainPaneContext: state.command.mainPaneContext,
  isProcessing: state.command.isProcessing,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  submitCommand: (command: commandTypes.Command) => 
    dispatch(commandActions.submitCommand(command) as any),
  logoutSuccess: () => dispatch(authActions.logoutSuccess())
});

export default withRouter<any>(
  connect(mapStateToProps, mapDispatchToProps)(FooterView)
);