/* tslint:disable:max-line-length */
import { Region } from '../../../types';
import placeholder from './loremIpsum';
import { area } from './area';
import { realm } from './realm';

export const region: Region = {
  id: 1,
  name: 'TODO: Placeholder Region',
  shortDescription: 'TODO: Placeholder Region short desc',
  longDescription: placeholder,
  areas: [area],
  realm,
};
